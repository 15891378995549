* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  /* font-size: 10px; */
  /* 10px / 16px = 0.625 = 62.5% */
  /* Percentage of user's browser font-size setting */
  font-size: 62.5%;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

body {
  font-family: "Aleo", serif;
  line-height: 1.3;
  font-weight: 400;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: auto;
}

a:link,
a:visited,
a:hover,
a:focus,
a:active,
a {
  color: #333;
  width: fit-content;
}

.hidden {
  display: none !important;
}

.full--screen {
  width: 100%;
  height: 100%;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  font-size: 1.8rem;
  height: 100%;
  width: 100%;
}

.media--icon,
.david--links--list li a i {
  font-size: 3rem;
  transition: all 0.3s;
}

.media--icon-img {
  height: 4rem;
  width: 4rem;
}

.media--icon:hover {
  animation: wiggle 2s linear;
  -webkit-animation: wiggle 2s linear;
}

.youtube--logo {
  color: #b51a00;
}

.spotify--logo {
  color: #669c35;
}

.apple--logo {
  color: #fff;
}

.amazon--logo {
  color: orange;
  border-radius: 50%;
}

.form--container {
  opacity: 0;
  animation: fade-in 0.3s forwards;
  -webkit-animation: fade-in 0.3s forwards;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  z-index: 2;
}

.form {
  width: 75% !important;
  height: 75% !important;
  box-shadow: 0 0.4rem 0.4rem 0.6rem rgba(0, 0, 0, 0.04);
  background-color: #fff;
  color: #131a22;
  border-radius: 0.4rem;
  padding: 1.8rem 3.6rem 1.8rem 3.6rem;

  transform: scale(0);
  animation: expand 0.3s forwards;
}

.form--p {
  padding: 1rem 0;
  letter-spacing: 0.02rem;
}

.exit--icon,
.exit--icon-menu,
.exit--booking {
  color: #131a22;
  position: fixed;
  top: 2.5%;
  right: 2.5%;
  font-size: 2.1rem;
  cursor: pointer;
  z-index: 2;
}

.exit--icon-menu {
  color: #fff;
  z-index: 3;
  top: 3%;
  right: 3%;
}

.submit--booking--form-btn,
.submit--contact--form-btn {
  padding: 1.4rem;
  margin-bottom: 1.2rem;
  background: none;
  border: none;
  border-radius: 0.4rem;
  background-color: #c38547;
  color: #222;
  font-size: 1.8rem;
  letter-spacing: 0.1rem;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: 0 0rem 0.4rem rgba(0, 0, 0, 0.04);
}

.submit--booking--form-btn:hover,
.submit--contact--form-btn:hover {
  background-color: #eddac8;
}

.film-strip--container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.film-strip {
  padding: 2.1rem;
  background-color: #000;
  width: 100%;
}

.collapse {
  animation: collapse 0.3s forwards;
  /* -webkit-animation: collapse 0.3s forwards; */
}

.fade-in {
  animation: fade-in 0.3s forwards;
  -webkit-animation: fade-in 0.3s forwards;
}

.fade-out {
  animation: fade-out 0.3s forwards;
  -webkit-animation: fade-out 0.3s forwards;
}

@keyframes expand {
  0% {
    transform: scale(0.1);
  }
  20% {
    transform: scale(0.2);
  }
  30% {
    transform: scale(0.3);
  }
  40% {
    transform: scale(0.4);
  }
  50% {
    transform: scale(0.5);
  }
  60% {
    transform: scale(0.6);
  }
  70% {
    transform: scale(0.7);
  }
  80% {
    transform: scale(0.8);
  }
  90% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

/* webkit keyframes see more */

@keyframes collapse {
  0% {
    transform: scale(0.9);
  }
  20% {
    transform: scale(0.8);
  }
  30% {
    transform: scale(0.7);
  }
  40% {
    transform: scale(0.6);
  }
  50% {
    transform: scale(0.5);
  }
  60% {
    transform: scale(0.4);
  }
  70% {
    transform: scale(0.3);
  }
  80% {
    transform: scale(0.2);
  }
  90% {
    transform: scale(0.1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
